@tailwind base;
@tailwind components;
@tailwind utilities;

@import "components";

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica-Bold.ttf') format('ttf'),
       url('../assets/fonts/Helvetica-BoldOblique.ttf') format('ttf'),
       url('../assets/fonts/helvetica-compressed-5871d14b6903a.otf') format('otf'),
       url('../assets/fonts/helvetica-light-587ebe5a59211.ttf') format('ttf'),
       url('../assets/fonts/Helvetica-Oblique.ttf') format('ttf'),
       url('../assets/fonts/helvetica-rounded-bold-5871d05ead8de.otf') format('otf'),
       url('../assets/fonts/Helvetica.ttf') format('ttf');
}

@font-face {
  font-family: "Sequel";
  src: url("../assets/fonts//sequel-100-black-45.ttf");
}

@layer base {
  html{
    font-family: 'Helvetica', sans-serif;
  }
}

body{
  background-color: #091615;
  color: #FFFFFF;
  max-width: 100vw;
  overflow-x: hidden;
}

.header {
  height: 75px;
  background: rgba(9, 22, 21, 0.80);
  backdrop-filter: blur(16px);
  color: white;
  position: fixed;
  z-index: 99999;
  width: 100vw;
}
.animated-text{
  overflow: hidden;
  animation: anim 10s infinite cubic-bezier(.94,.05,.38,1);
}
.animated-text-mini{
  overflow: hidden;
  animation: anim-mini 10s infinite cubic-bezier(.94,.05,.38,1);
}
.line{
  text-transform: uppercase;
  /* text-align: center; */
}

/* .line:first-child{
  animation: anim 4s infinite 4s;
} */

@keyframes anim {
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(-36px);
  }
  50%{
    transform: translateY(-72px);
  }
  75%{
    transform: translateY(-108px);
  }
  100%{
    transform: translateY(-144px);
  }
}

@keyframes anim-mini {
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(-28px);
  }
  50%{
    transform: translateY(-56px);
  }
  75%{
    transform: translateY(-84px);
  }
  100%{
    transform: translateY(-112px);
  }
}